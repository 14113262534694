<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="margin-top: 50px">
        <el-col :span="8" :offset="8">
          <el-form
            :model="addForm"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <el-form-item label="货主简称" prop="shortname">
              {{ addForm.shortname }}
            </el-form-item>
            <el-form-item label="货主名称" prop="name">
              {{ addForm.name }}
            </el-form-item>
            <el-form-item label="货主电话" prop="telephone">
              {{ addForm.telephone }}
            </el-form-item>
            <el-form-item label="货主地址" prop="address">
              {{
                addForm.addrProvince +
                "/" +
                addForm.addrCity +
                "/" +
                addForm.addrCounty
              }}
            </el-form-item>
            <el-form-item label="详细地址" prop="addrDetail">
              {{ addForm.addrDetail }}
            </el-form-item>
            <el-form-item label="道路运输许可" prop="transportlicencecode">
              {{ addForm.transportlicencecode }}
            </el-form-item>
            <el-form-item label="组织机构代码" prop="orgcode">
              {{ addForm.orgcode }}
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" :offset="3">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="企业营业执照"
            v-model="addForm.businesslicense"
          />
        </el-col>
        <el-col :span="6">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="运营许可证"
            v-model="addForm.transportlicece"
          />
        </el-col>
        <el-col :span="6">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="资料信息"
            v-model="addForm.filingvoucher"
        /></el-col>
      </el-row>
      <el-row style="margin-top: 50px">
        <el-col :offset="10">
          <template v-if="addForm.status == 0">
            <el-button
              size="mini"
              type="primary"
              class="buttons"
              @click="handlePass()"
              >通过</el-button
            >
            <el-button
              size="mini"
              type="danger"
              class="buttons"
              style="background: rgb(255, 89, 38); color: rgb(255, 255, 255)"
              @click="handleReject()"
              >驳回</el-button
            >
          </template>
          <el-button size="small" class="buttons" @click="goBack"
            >返回</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { codeToText } from "element-china-area-data";
import ImgUpload from "@/components/Img-upload.vue";
import { mapState } from "vuex";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        shortname: "",
        name: "",
        telephone: "",
        address: [],
        addrDetail: "",
        transportlicencecode: "",
        orgcode: "",
        transportlicece: "",
        status: "1",
      },
      rules: {},
    };
  },
  components: {
    ImgUpload,
  },
  created() {
    if (this.$route.query.id) {
      this.$http(
        "api1",
        "/api/biz/EnterpriseInfo/info/" + this.$route.query.id,
        "get"
      ).then((res) => {
        this.addForm = res.data.data;
        this.addForm.address = this.addForm.addrCodeStr.split(",");
      });
    }
  },
  mounted() {},
  computed: {
    ...mapState(["pendingCount"]),
  },
  methods: {
    async confirmAdd() {
      const { data } = await this.$http(
        "api1",
        "/api/cargo/CargoOwner/save",
        "post",
        {
          ...this.addForm,
          addrProvince:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[0]]
              : "",
          addrCity:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[1]]
              : "",
          addrCounty:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[2]]
              : "",
          addrCodeStr: this.addForm.address.join(","),
        }
      );
      if (data.code != 0) {
        return this.$message.error(data.msg);
      } else {
        this.$message.success("操作成功");
        this.goBack();
      }
      
    },
    async confirmUpdate() {
      const { data } = await this.$http(
        "api1",
        "/api/cargo/CargoOwner/update",
        "post",
        {
          id: this.$route.query.id,
          ...this.addForm,
          addrProvince:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[0]]
              : "",
          addrCity:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[1]]
              : "",
          addrCounty:
            this.addForm.address.length > 0
              ? codeToText[this.addForm.address[2]]
              : "",
          addrCodeStr: this.addForm.address.join(","),
        }
      );
      if (data.code != 0) {
        return this.$message.error(data.msg);
      } else {
        this.$message.success("操作成功");
        this.goBack();
      }

    },
    handlePass() {
      this.$messageBox
        .confirm("确认" + "通过" + "该货主,是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/EnterpriseInfo/auditAccept",
            "post",
            {
              enterpriseId: this.$route.query.id,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        });
    },
    handleReject() {
      this.$messageBox
        .prompt("请输入驳回原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(async ({ value }) => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/EnterpriseInfo/auditRefuse",
            "post",
            {
              enterpriseId: this.$route.query.id,
              ...this.addForm,
              refuseReason: value,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        })
        .catch(() => {});
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
